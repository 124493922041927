export default [
  {
    title: 'Главная',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Аналитика',
    route: 'analytics',
    icon: 'BarChartIcon',
  },
  {
    title: 'Касса',
    route: 'kassa',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Заказы',
    route: 'orders',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Пользователи',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'Бренды',
    route: 'brands',
    icon: 'UsersIcon',
  },
]
